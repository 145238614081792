<template>
    <v-main class="ma-4" >
        <v-container class="fill-height" fluid >
            <v-row align="center" justify="center" class="ma-1" color="primary">
                <v-col cols="12" sm="8" md="4">
                    <main-title/>
                    <v-form v-model="valid" ref="form">
                        <v-text-field  :rules="rules.fieldRules" label="First Name" v-model="form.firstname"  prepend-icon="mdi-account" type="text"/>
                        <v-text-field  label="Middle Name"  v-model="form.middlename"  prepend-icon="mdi-account" type="text"/>
                        <v-text-field :rules="rules.fieldRules" label="Last Name"  v-model="form.lastname"  prepend-icon="mdi-account" type="text"/>
                        <v-text-field v-on:blur="emailCheck"  :rules="rules.emailRules" label="Email"  v-model="form.email"  prepend-icon="mdi-email" type="text"/>
                        <v-text-field :rules="rules.idRules" v-on:blur="idCheck" v-mask="'AAA-#####'" label="Employee ID No. (e.g. MMC-00000)" v-model="form.employee_id"  prepend-icon="mdi-credit-card" type="text"/>
                        <v-text-field id="password" label="Password" v-model="form.password" name="password" min="8"
                                      :append-icon="e2 ? 'visibility' : 'visibility_off'"
                                      :type="!e2 ? 'password' : 'text'"
                                      :rules="rules.passwordRules"
                                      @click:append="() => (e2 = !e2)"  prepend-icon="lock" />
                        <v-text-field  label="Retype Password" v-model="form.retype"   min="8"
                                       :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                       :type="!e1 ? 'password' : 'text'"
                                       :rules="[(form.password === form.retype) || 'Password not match']"
                                       @click:append="() => (e1 = !e1)"  prepend-icon="lock" />
                        <v-btn block class="mt-5" :loading="registering" :disabled="registering" color="primary" @click.prevent="register">
                            Register
                            <template v-slot:loader>
                                <span>Please wait...</span>
                            </template>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn block class="mt-3 " v-if="!registering" @click.prevent="toLogin" >
                            Login Here
                        </v-btn>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
    import user from "../../api/user"

    // import axios from "axios";
    export default {
        name: "Register",
        data(){
            return{
                valid: false,
                e1 : false,
                e2: false,
                hasEmailError: false,
                hasIdError: false,
                registering: false,
                submitted: false,
                form: {
                    firstname: '',
                    middlename: '',
                    lastname: '',
                    email: '',
                    employee_id: '',
                    password: '',
                    retype: ''
                },
                rules:{
                    passwordRules: [
                        v => !!v || 'Password is required',
                        v => (v && v.length >= 5) || 'Password must contain the following: minimum of 8  characters, uppercase letter, a number and special character.',
                        v => /(?=.*[A-Z])/.test(v) || 'Password must contain the following: minimum of 8  characters, uppercase letter, a number and special character.',
                        v => /(?=.*\d)/.test(v) || 'Password must contain the following: minimum of 8  characters, uppercase letter, a number and special character.',
                        v => /([!@$%_])/.test(v) || 'Password must contain the following: minimum of 8  characters, uppercase letter, a number and special character.'
                    ],
                    fieldRules: [
                        v => !!v || 'This field is required',
                    ],
                    emailRules: [
                        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Please provide a valid email',
                        v => !!v || 'This field is required',
                        v => !v || this.hasEmailError === false || this.errors.email[0],
                    ],
                    idRules: [
                        v => !!v || 'This field is required',
                        v => !v || this.hasIdError === false || this.errors.employee_id[0],
                    ]
                },
                errors: [],

            }
        },
        computed:{

        },
        methods: {
            toLogin(){
                this.$router.push({name: "Welcome"})
            },
            emailCheck(){
                this.hasEmailError = false;
                if(this.submitted){
                    this.$refs.form.validate();
                }
            },
            idCheck(){
                this.hasIdError = false;
                if(this.submitted){
                    this.$refs.form.validate();
                }
            },
            register(){
                if(this.$refs.form.validate()){
                    this.registering = true;
                    this.submitted = true;
                    user.register(this.form)
                        .then((response) =>{
                            this.registering = false;
                            this.$store.dispatch('setAlert',response.data);
                            this.$router.push({name: 'Welcome'});
                        })
                        .catch(error => {
                            this.registering = false;
                            this.errors = error.response.data.errors;
                            if( this.errors.employee_id){
                                this.hasIdError = true;
                                this.$refs.form.validate();
                            }
                            if( this.errors.email){
                                this.hasEmailError = true;
                                this.$refs.form.validate();
                            }
                        });
                }

            },

        }
    }
</script>

<style scoped>

</style>