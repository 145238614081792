<template>
    <basic-layout>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="4">
                    <app-bar :title="title"/>
                    <div class="">
                        <v-row v-if="!payroll_data" align="center" justify="center">
                            <v-col>
                                <div class="font-weight-light primary--text text-center">
                                    <div class="text-center" align="center" justify="center">
                                        <v-progress-circular :size="50" :width="5" color="green"
                                                             indeterminate></v-progress-circular>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row v-else align="center" justify="center">
                            <v-col class="ma-3">
                                <v-flex sm12 xs12 lg12>
                                    <v-card color="white" class="pa-4 mb-3">
                                        <v-layout wrap row justify-space-between class="ma-0">
                                            <v-flex xs12>
                                                <v-spacer></v-spacer>
                                                <span :style="{ fontWeight: 'bold',fontSize: '17px'}">Summary</span>
                                            </v-flex>
                                            <v-flex xs12>
                                                <span :style="{ fontWeight: 'normal', fontSize: '15px'}">Gross</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}">
                                                <v-spacer></v-spacer>₱{{ formatPrice(payroll_data.gross_ammount_earned)}}</span>
                                                <v-spacer></v-spacer>
                                                <span :style="{ fontWeight: 'bold', fontSize: '15px'}">Total Deduction</span>
                                                <span style="float: right" :style="{ fontWeight: 'bold', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.total_deduction) }}</span>
                                                <v-spacer></v-spacer>
                                                <hr style="width: 100%;border: 1px solid grey;">
                                                <span :style="{ fontWeight: 'normal', fontSize: '15px'}">NET Pay</span>
                                                <v-spacer></v-spacer>
                                                <span style="margin-left: 20px" :style="{ fontWeight: 'normal', fontSize: '15px'}">1st half</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.first_half) }}</span>
                                                <v-spacer></v-spacer>
                                                <span style="margin-left: 20px" :style="{ fontWeight: 'normal', fontSize: '15px'}">2nd half</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.second_half) }}</span>
                                                <v-spacer></v-spacer>
<!--                                                <span style="margin-left: 20px" :style="{ fontWeight: 'bold', fontSize: '15px'}">Total</span>-->
<!--                                                <span style="float: right" :style="{ fontWeight: 'bold', fontSize: '15px'}"><v-spacer></v-spacer> ₱{{ formatPrice(payroll_data.gross_ammount_earned - payroll_data.total_deduction) }}</span>-->
<!--                                                <v-spacer></v-spacer>-->
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-flex sm12 xs12 lg12>
                                    <v-card color="white" class="pa-4 mb-3">
                                        <v-layout wrap row justify-space-between class="ma-0">
                                            <v-flex xs12>
                                                <span :style="{ fontWeight: 'bold',fontSize: '17px'}">Details</span>
                                            </v-flex>
                                            <v-flex xs12>
                                                <span :style="{ fontWeight: 'normal', fontStyle:'italic',fontSize: '15px'}">Compensation</span>
                                                <v-spacer></v-spacer>
                                                <!--                            Salaries and Wages-Regular-->
                                                <span style="margin-left: 20px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Basic Salary</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.salaries_wages_regular) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            PERA-->
                                                <span style="margin-left: 20px" :style="{ fontWeight: 'normal', fontSize: '15px'}">PERA</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.pera) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            Subs and Laundry Allowance-->
                                                <span style="margin-left: 20px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Subs. & Laundry Allowance</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.subsistence_laundry_allowance) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            Adjustment-->
                                                <span style="margin-left: 20px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Adjustment</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.adjustment) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            Gross Amount Earned-->
                                                <span style="margin-left: 20px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Gross Amount Earned</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.gross_ammount_earned) }}</span>
                                                <v-spacer></v-spacer>
                                            </v-flex>
                                            <v-flex xs12>
                                                <span :style="{ fontWeight: 'bold', fontStyle:'italic',fontSize: '15px'}">Deductions</span>
                                                <v-spacer></v-spacer>
                                                <!--                            With holding Tax (412)-->
                                                <span style="margin-left: 20px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Withholding Tax</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.withholding_tax) }}</span>
                                                <v-spacer></v-spacer>
                                                <span style="margin-left: 20px" :style="{ fontStyle:'italic', fontWeight: 'normal', fontSize: '15px'}">GSIS</span>
                                                <v-spacer></v-spacer>
                                                <!--                            Conso Loan-->
                                                <span style="margin-left: 40px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Consolidated Loan</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.consolidated_loan) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            P.I.R-->
                                                <span style="margin-left: 40px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Policy Loan Regular</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.policy_loan_regular) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            P.I.O-->
                                                <span style="margin-left: 40px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Policy Loan Optional</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.policy_loan_optional) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            R.L.I.P (9%)-->
                                                <span style="margin-left: 40px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Life & Retrmnt Ins. Prem.</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.life_retirement) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            O.I.P.U.O.I.P-->
                                                <span style="margin-left: 40px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Unli Opt. Life Insurance</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.unlimited_optional_life_insurance) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            Calamity / E.Loan-->
                                                <span style="margin-left: 40px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Calamity / E.Loan</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.emergency_calamity_loan) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            Gfal / E.Loan-->
                                                <span style="margin-left: 40px" :style="{ fontWeight: 'normal', fontSize: '15px'}">GSIS Fin. Asst. Loan</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.gsis_finance_assistance_e_loan) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            Educ. Assist.-->
                                                <span style="margin-left: 40px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Educational Assist.</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.educational_assitance) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            Multi purpose loan-->
                                                <span style="margin-left: 40px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Multi-purpose Loan</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.multipurpose_loan) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            Computer loan-->
                                                <span style="margin-left: 40px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Computer Loan</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.computer_loan) }}</span>
                                                <v-spacer></v-spacer>
                                                <span style="margin-left: 20px" :style="{fontStyle:'italic', fontWeight: 'normal', fontSize: '15px'}">HMDF</span>
                                                <v-spacer></v-spacer>
                                                <!--                            P.P-->
                                                <span style="margin-left: 40px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Pag-ibig Premium</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.premium) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            MPL/ H. Loan-->
                                                <span style="margin-left: 40px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Mult Prps Housing Loan</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.multipurpose_calamity_loan) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            Housing-->
                                                <span style="margin-left: 40px" :style="{ fontWeight: 'normal', fontSize: '15px'}">Housing</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.housing) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            SSS-->
                                                <span style="margin-left: 20px" :style="{fontStyle:'normal', fontWeight: 'normal', fontSize: '15px'}">SSS</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.sss) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            PHILHEALTH (415) (2.75%)-->
                                                <span style="margin-left: 20px" :style="{fontStyle:'normal', fontWeight: 'normal', fontSize: '15px'}">PHILHEALTH</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.philhealth) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            Leave w/o Pay-->
                                                <span style="margin-left: 20px" :style="{fontStyle:'normal', fontWeight: 'normal', fontSize: '15px'}">Leave w/o Pay</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.leave_without_pay) }}</span>
                                                <v-spacer></v-spacer>
                                                <!--                            Landbank salary loan-->
                                                <span style="margin-left: 20px" :style="{fontStyle:'normal', fontWeight: 'normal', fontSize: '15px'}">Landbank Salary Loan</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.landbank_salary_loan) }}</span>
                                                <v-spacer></v-spacer>
                                              <!--                            Coop-->
                                                <span style="margin-left: 20px" :style="{fontStyle:'normal', fontWeight: 'normal', fontSize: '15px'}"> Coop.</span>
                                                <span style="float: right" :style="{ fontWeight: 'normal', fontSize: '15px'}"><v-spacer></v-spacer>₱{{ formatPrice(payroll_data.coop) }}</span>
                                                <v-spacer></v-spacer>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-btn block class="mt-5" color="primary" @click.prevent="print">
                                    Print
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </basic-layout>
</template>

<script>
    import payrolls from "../../api/payrolls";
    import printpayroll from "../../pdf/printpayroll";

    export default {
        name: "PayslipDetail",
        data() {
            return {
                form: {
                    employee_id: '',
                    payroll: '',
                },
                payroll_data: null,

            }
        },
        methods: {
            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            print() {
                printpayroll.printPayrollSlip(this.payroll_data, this.user);
            },
            load_data() {
                this.form.employee_id = this.user.employee_id;
                this.form.payroll = this.$store.getters.getPayroll;
                payrolls.payrollslip(this.form)
                    .then(response => {
                        this.payroll_data = response.data[0];
                    })
            }
        },
        computed: {
            title() {
                return this.$store.getters.getPayrollTitle;
            },
            user() {
                return this.$store.getters.getCurrentUser;
            },
        },
        mounted() {
            this.load_data();
        }
    }
</script>

<style scoped>

</style>