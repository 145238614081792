<template>
    <v-app-bar app flat  color="white">
        <v-app-bar-nav-icon @click="app.drawer = !app.drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="text-uppercase">
            <span  class="font-weight-light primary--text">minsu</span>
            <span class="primary--text">app</span>
        </v-toolbar-title>
    </v-app-bar>
</template>

<script>
    export default {
        name: "navbar",
        props: ['app'],
        data: () => ({

        }),
    }
</script>

<style scoped>

</style>